<template>
    <div id="resume">Resume</div>

    <div id="lang-switcher">
        <a href="/cv/en">EN</a>&nbsp; <a href="/cv/ja">日</a>&nbsp;
        <a href="/cv/zh">中</a>
    </div>

    <div id="basicinfo">
        <h1 id="name">Xiaorui Li</h1>
        <h4 id="from">Master's Program in Computer Science</h4>
        <h4 id="from">University of Tsukuba, Japan</h4>
        <div id="links">
            <a class="link" id="email" href="mailto:intern@xiaorui.li"><img class="inline-icon"
                    src="@/assets/mail-open-minus-svgrepo-com.svg" />intern@xiaorui.li</a>
            <a class="link" id="tel" href="tel:+81 080-3518-1647"><img class="inline-icon"
                    src="@/assets/phone-svgrepo-com.svg" />+81 080-3518-1647</a>
            <a class="link" id="homepage" href="https://github.com/MerlynAllen"><img class="inline-icon"
                    src="@/assets/github-svgrepo-com.svg" />MerlynAllen</a>
        </div>
    </div>

    <hr />

    <div class="column" id="education">
        <h2 class="column-title">Education</h2>
        <div class="item">
            <h3 class="item-title">Sep 2019<br />⫶<br />Jun 2023</h3>
            <p class="item-details">
                <b>Jilin University, China</b><br />Tangaoqing Honors Program in Chemistry (Major) <br />GPA: 3.54 of
                4.0
            </p>
            <h4 class="item-comments">Bachlor's</h4>
        </div>
        <div class="item">
            <h3 class="item-title">Sep 2021<br />⫶<br />Jun 2023</h3>
            <p class="item-details">
                <b>Jilin University, China</b><br />Computer Science (Minor) <br />GPA: 3.57 of 4.0
            </p>
            <h4 class="item-comments">Bachlor's</h4>
        </div>
        <div class="item">
            <h3 class="item-title">Dec 2023<br />⫶<br />Mar 2024</h3>
            <p class="item-details">
                <b>University of Tsukuba, Japan</b><br />Department of Computer Science<br />SoftLab<br />
            </p>
            <h4 class="item-comments">Research Student</h4>
        </div>
        <div class="item">
            <h3 class="item-title">Apr 2024<br />⫶<br />Mar 2026<sup>1</sup></h3>
            <p class="item-details">
                <b>University of Tsukuba, Japan</b><br />Department of Computer Science<br />SoftLab<br />
            </p>
            <h4 class="item-comments">Master's</h4>
        </div>
    </div>

    <div class="column" id="language">
        <h2 class="column-title">Language Proficiency</h2>
        <div class="item">
            <h3 class="item-title">Japanese</h3>
            <p class="item-details"><b>JLPT N2</b> 130</p>
        </div>
        <div class="item">
            <h3 class="item-title">English</h3>
            <p class="item-details"><b>TOEIC</b> 895</p>
        </div>
    </div>

    <hr />

    <div class="column" id="project">
        <h2 class="column-title">Project Experience</h2>
        <div class="item">
            <h3 class="item-title">Jun 2021</h3>
            <p class="item-details">
                <b>Building Artificial Neural Network from scratch to detect carbonyl
                    group from Infrared Spectrum data</b><br />A course project of Prof. Fengfeng Zhou's "Python and
                Machine
                Learning" course. <br />Built neural networks, back-propagation
                and stocastic gradient descend functions from scratch with Numpy. Used
                infrared spectrum data source from NIST.
            </p>
            <div class="item-comments"></div>
        </div>
        <div class="item">
            <h3 class="item-title">May 2022<br />⫶<br />Present</h3>
            <p class="item-details">
                <b>Maintaining virtual AS in DN42</b><br />DN42 is a distributed experimental VPN network which employs
                nowadays Internet infrastructural technologies (BGP, Whois database,
                DNS, CA Authority, etc.) to simulate building a real Internet.<br />
                Acting as an AS maintainer (AS4242422380). Holding
                <code>172.22.125.224/27</code> IPv4 and a
                <code>fd00:feed:beef::/48</code> IPv6 prefixes. Created eBGP sessions
                with 5 peers via WireGuard tunnels, and iBGP session between 2 edge
                routers via Zerotier. Connections inside AS are established via
                Zerotier. An authourative DNS server and a sample (clone version of own home page) site is running in
                own AS.
            </p>
            <div class="item-comments">
                <div>
                    <a class="link" href="https://dn42.eu/"><img class="inline-icon"
                            src="@/assets/link-alt-1-svgrepo-com.svg" />Official Wiki</a>
                </div>
            </div>
        </div>

        <div class="item">
            <h3 class="item-title">Dec 2022 <br />⫶<br /> May 2023</h3>
            <p class="item-details">
                <b>QUIC protocol based universal message transport module written in Rust</b><br />
                Implemented a QUIC protocol based universal message transport module in Rust language, with
                asynchoronous IO support. Exposing user-friendly APIs to send and receive messages.

            </p>
            <div class="item-comments"></div>
        </div>
        <div class="item">
            <h3 class="item-title">Dec 2023 <br />⫶<br />Mar 2024</h3>
            <p class="item-details">
                <b>A Linux routing table seperator using eBPF programs</b><br />

                Implemented a networking tool in Linux written in Rust to enable routing table level seperation by
                groups of processes.
                Process group is enabled by appending program to specific Control Groups. Using eBPF programs to hook
                onto
                the <code>socket()</code> system call. Routing seperation is achieved by adding firewall marks to the
                sockets, and using <code>iproute</code> to setup routing rules based on the marks, leading to different
                routing tables.
            </p>
            <div class="item-comments"></div>
        </div>
        <div class="item">
            <h3 class="item-title">Mar 2024<br />⫶<br />Present</h3>
            <p class="item-details">
                <b>Privacy-preserving decentralized Virtual Private Network system with Sybil Attack resistance
                </b><br />
                A currently ongoing research project in SoftLab, under the guidance of Prof. Shinjo Yasushi's,
                University
                of Tsukuba.<br />
                Aiming to build a decentralized VPN system with the resistance against Sybil attack.
            </p>
            <div class="item-comments"></div>
        </div>

        <!-- <div class="item">
      <h3 class="item-title">Apr 2021 - May 2021</h3>
      <p class="item-details">
        <b>Machine learning methods to screen molecular sieve structures</b
        ><br />A research project in the lab of Prof. Yi Li, Jilin University.
      </p>
      <div class="item-comments"></div>
    </div> -->
    </div>

    <div class="column" id="skills">
        <h2 class="column-title">Skills</h2>
        <div class="item">
            <h3 class="item-title">Programming</h3>
            <p class="item-details"><b>Proficient</b><br />Rust, Python<br />
                <b>Familiar</b><br />C, C++, JavaScript, Java, Vue.js, Shell, Go
            </p>
        </div>
        <div class="item">
            <h3 class="item-title">Others</h3>
            <p class="item-details">Linux, Git, Docker, LaTeX
            </p>
        </div>
    </div>

    <hr />

    <div class="column">
        <h2 class="column-title">Personal Projects</h2>
        <!-- <div class="item">
            <h3 class="item-title">June 2022 <br />⫶<br /> Present</h3>
            <p class="item-details">
                <b>Merlyn & Merlyn Said</b><br />A personal website & blog using Vue.js
                and Node.js with Cloudflare Workers.
            </p>
            <div class="item-comments">
                <a class="link" id="homepage" href="https://github.com/MerlynAllen/MerlynAllen"><img class="inline-icon"
                        src="@/assets/github-svgrepo-com.svg" />MerlynAllen</a><a class="link" id="homepage"
                    href="https://github.com/MerlynAllen/merlyn-said"><img class="inline-icon"
                        src="@/assets/github-svgrepo-com.svg" />merlyn-said</a>
                <a class="link" id="homepage" href="https://me.xiaorui.li"><img class="inline-icon"
                        src="@/assets/link-alt-1-svgrepo-com.svg" />xiaorui.li</a>
                <a class="link" id="homepage" href="https://said.merlyn.dev"><img class="inline-icon"
                        src="@/assets/link-alt-1-svgrepo-com.svg" />said.merlyn.dev</a>
            </div>
        </div> -->
        <div class="item">
            <h3 class="item-title">Jun 2022</h3>
            <p class="item-details">
                <b>B-i.li URL shortener</b><br />A serverless URL shortener & personal file sharing & URL proxy service
                built with JavaScript powered by Cloudflare Workers and Telegram Bot.
            </p>
            <div class="item-comments">
                <a class="link" id="homepage" href="https://t.me/bili_short_url_bot"><img class="inline-icon"
                        src="@/assets/link-alt-1-svgrepo-com.svg" />Telegram Bot</a>
            </div>
        </div>
        <div class="item">
            <h3 class="item-title">Nov 2023</h3>
            <p class="item-details">
                <b>Notification pushing service</b><br />
                A notification pushing service using WebPush, which enables pushing important information from other
                personal automation scripts to mobile devices and desktop devices. And also enables multi-platform
                clipboard sharing. B-i.li URL shortener is also intergrated with this service.
            </p>
            <div class="item-comments">
            </div>
        </div>
        <div class="item">
            <h3 class="item-title">Nov 2023</h3>
            <p class="item-details">
                <b>SMS hosting service</b><br />
                A service enables sending and receiving SMS everywhere without a SIM card. The service is hosted on a
                Raspberry Pi. The pushing service is also intergrated with this service.
            </p>
            <div class="item-comments">
            </div>
        </div>

        <div class="column" id="honors">
            <h2 class="column-title">Honors and Awards</h2>
            <div class="item">
                <h3 class="item-title">Sep 2021</h3>
                <p class="item-details">
                    <b>Third-class scholarship of Jilin University</b>
                </p>
            </div>
        </div>

        <div class="column" id="">
            <h2 class="column-title">Extracurrirular Activities</h2>
            <div class="item">
                <h3 class="item-title">Sep 2021<br />⫶<br />Jun 2023</h3>
                <p class="item-details">
                    <b>Member of Chinese Orchestra of Jilin University, Principal Bamboo flutist.</b>
                </p>
            </div>
            <div class="item">
                <h3 class="item-title">Sep 2021<br />⫶<br />Jun 2023</h3>
                <p class="item-details">
                    <b>President of Student Bamboo Flute Club of Jilin University</b>
                </p>
            </div>
        </div>
        <hr />
        <div id="footnote">
            <div class="footnote">
                <sup>1</sup> Expected graduation date.
            </div>
            <div class="timestamp">
                Last updated on 2024-03-18
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "App",
    components: {},
};
</script>

<style>
@import "@/style/main.css";
@import "@/style/en.css";
</style>
